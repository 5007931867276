/* for customisation options see https://bulma.io/documentation/customize/variables/ */

@use "bulma/bulma"

// multiline buttons courtesy of https://github.com/jgthms/bulma/issues/2787#issuecomment-791950213
.button.is-multiline
  min-height: 2.25em
  white-space: unset
  height: auto
  flex-direction: column
